<template>
    <div>
        <Divider dashed><span class="divider-text">供应商运维账单</span></Divider>
        <component :is="searchComponent"></component>
    </div>
</template>

<script>
export default {
  components: {
    maintenanceReconciliationComponent: () => import('../common/maintenanceReconciliation')
  },
  data () {
    return {
      searchComponent: 'maintenanceReconciliationComponent'
    }
  },
  created () {
  },
  methods: {
  }
}
</script>
